import type { Ref } from 'vue'
import { FetchError } from 'ofetch'
import Endpoints from '~/models/constants/Endpoints'
import { RestService } from '~/services/RestService'
import type { Space } from '~/models/space/Space'

class SpaceService extends RestService {
  async createSpace(space: Partial<Space>): Promise<any> {
    return await this.postSecured(Endpoints.SPACES, space, {})
  }

  async updateSpace(space: Partial<Space>) {
    await this.put(`${Endpoints.SPACES}`, space, {})
  }

  async deleteSpace(spaceId: string) {
    await this.delete(`${Endpoints.SPACES}/${spaceId}`, {})
  }

  async getSpaceById(spaceId: string) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.find(`${Endpoints.SPACES}/${spaceId}`, {})

    return this.handleResponse(response)
  }

  async getPendingSpaces() {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.findSecured(`${Endpoints.SPACES}/pending`, {})

    return this.handleResponse(response)
  }

  async getFeaturedSpaces() {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.find(`${Endpoints.SPACES}/featureds`, {})

    return this.handleResponse(response)
  }

  async getRelatedSpaces(spaceId: string) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.find(`${Endpoints.SPACES}/${spaceId}/relateds`, {})

    return this.handleResponse(response)
  }

  async approveSpace(spaceId: string) {
    await this.put(`${Endpoints.SPACES}/${spaceId}/approve`, {}, {})
  }

  async getAuthenticatedUserSpaces() {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.findSecured(`${Endpoints.SPACES}/user`, {})

    return this.handleResponse(response)
  }

  async updateSpacePhoneClicksCount(spaceId: string) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.patch(`${Endpoints.SPACES}/${spaceId}/phone-clicks`, {}, {})

    return this.handleResponse(response)
  }

  async verifyOwnershipToken(ownerRequestToken: string, spaceId: string) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.find(
        `${Endpoints.SPACES}/${spaceId}/ownership/verify`,
        {
          params: { ownerRequestToken },
        },
        false
      )

    return this.handleResponse(response)
  }

  async claimOwnership(user: any, spaceId: string) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.post(
        `${Endpoints.SPACES}/${spaceId}/ownership/claim`,
        {
          ...user,
        },
        {}
      )

    return this.handleResponse(response)
  }
}

export default new SpaceService()
